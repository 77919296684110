.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.flex-align-center {
  display: flex !important;
  align-items: center !important;
}

.insights .items-center {
  display: flex !important;
  justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media print {
  a {
    page-break-inside: avoid;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    page-break-before: always;
  }
  table,
  figure {
    page-break-inside: avoid;
  }
  @page {
    size: A4 portrait;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ant-menu-item-selected {
  background-color: #ffffff !important;
}

.App-link {
  color: #61dafb;
}

a.detail {
  color: #00a2ac;
  font-weight: 500;
  float: right;
  margin-top: 12px;
  margin-right: 8px;
  font-size: 12px;
  line-height: 16px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

:root {
  --form-color: cyan;
}

.ant-radio-checked .ant-radio-inner {
  border-color: red !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: red;
}

.ant-radio:hover .ant-radio-inner {
  border-color: red;
}
.ant-radio-checked .ant-radio-inner:focus {
  border-color: red;
}

.performance-tabs .ant-tabs-nav {
  margin-bottom: 0px;
  background-color: #f9fafb;
  border-top: 1px solid rgba(208, 212, 220, 0.3);
}

.performance-tabs .ant-tabs-tab {
  background-color: #e6ebf2;
}

.widget-card .ant-card-head {
  border-bottom: none;
}

.insights .ant-table-thead .ant-table-cell {
  color: #76798b;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.insights .ant-table-footer {
  background-color: #ffffff;
}
