@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {font-family: 'Satoshi';}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


[data-amplify-authenticator] {

  --amplify-components-button-primary-background-color: #003D50;
  --amplify-components-authenticator-router-border-width: 0px;
  --amplify-components-authenticator-router-box-shadow: 0;

  --amplify-components-field-label-color: #003D50;

  --amplify-components-button-border-color: #D0D4DC;
  --amplify-components-textfield-border-color: #D0D4DC;
  --amplify-fonts-default-variable: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --amplify-components-button-font-weight:500;
  /**
  --amplify-components-passwordfield-button-color: red;
  --amplify-components-passwordfield-button-active-color: red;
  --amplify-components-passwordfield-button-hover-border-color: red;
  --amplify-components-passwordfield-button-focus-border-color: red;
  --amplify-components-passwordfield-button-disabled-border-color: red;
  */
/**
  --amplify-components-button-small-padding-block-end: 100px;
  --amplify-components-button-small-padding-block-start: 100px;
  --amplify-components-button-small-padding-inline-end: 100px;
  --amplify-components-button-small-padding-inline-start: 100px;
*/

/**
  --amplify-components-button-large-padding-block-end: 0px;
  --amplify-components-button-large-padding-block-start: 100px;
  --amplify-components-button-large-padding-inline-end: 100px;
  --amplify-components-button-large-padding-inline-start: 0px;
*/

/*
--amplify-components-button-padding-block-end: 20px;

  --amplify-components-button-padding-block-end: 0px;
  --amplify-components-button-padding-block-start: 0px;
  --amplify-components-button-padding-inline-end: 0px;
  --amplify-components-button-padding-inline-start: 100px;
*/
}
.ant-table table { 
  font-size: 12px; 
  font-weight: 500;
}
.ant-table-thead { 
  font-size: 12px; 
  font-weight: 800;
}

/*
.ant-tabs > .ant-tabs-nav { 
  margin-bottom: 0;
  padding-bottom: 0;
}


.ant-tabs-content-holder {
  padding: 0px;
  margin: 0px;
  border-color:red;
}*/


.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #575A70  !important; ;
  font-weight: 600;
  font-size: 12px;
  font-family: 'Inter';
}

.ant-tabs-ink-bar {
  border-bottom: 2px solid #00A2AC ;
}

.amplify-heading--2 {
  font-weight: var(--amplify-font-weights-thin);
}
.amplify-text {
  font-weight: var(--amplify-font-weights-thin);
}


.ant-collapse-content-box {
      padding: 0 !important;
}


@font-face {
  font-family: "Inter";
  src: local("Inter"),
   url("@fonts/Inter/static/Inter-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "Inter";
    src: local("Inter"),
     url("@fonts/Inter/static/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  }

  @font-face {
    font-family: "Inter";
    src: local("Inter"),
     url("@fonts/Inter/static/Inter-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
   url("@fonts/Inter/static/Inter-Black.ttf") format("truetype");
   font-weight: black;

  }

@font-face {
    font-family: "Inter";
    src: local("Inter"),
     url("@fonts/Inter/static/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  }

@font-face {
    font-family: "Inter";
    src: local("Inter"),
     url("@fonts/Inter/static/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
   url("@fonts/Inter/static/Inter-Medium.ttf") format("truetype");
font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
   url("@fonts/Inter/static/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
}





@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"),
   url("@fonts/Satoshi/Fonts/Satoshi-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "Satoshi";
    src: local("Satoshi"),
     url("@fonts/Satoshi/Fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700;
  }

  @font-face {
    font-family: "Satoshi";
    src: local("Satoshi"),
     url("@fonts/Satoshi/Fonts/Satoshi-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"),
   url("@fonts/Satoshi/Fonts/Satoshi-BoldItalic.ttf") format("truetype");
   font-style: italic;
   font-weight: bold;

  }

  @font-face {
    font-family: "Satoshi";
    src: local("Satoshi"),
     url("@fonts/Satoshi/Fonts/Satoshi-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  }

  @font-face {
    font-family: "Satoshi";
    src: local("Satoshi"),
     url("@fonts/Satoshi/Fonts/Satoshi-Light.ttf") format("truetype");
  font-weight: light;
}

@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"),
   url("@fonts/Satoshi/Fonts/Satoshi-Medium.ttf") format("truetype");
font-weight: 500;
}

@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"),
   url("@fonts/Satoshi/Fonts/Satoshi-MediumItalic.ttf") format("truetype");
font-weight: 500;
font-style: italic;
}

@font-face {
  font-family: "Satoshi";
  src: url("@fonts/Satoshi/Fonts/Satoshi-Variable.ttf") format("ttf supports variations"),
       url("@fonts/Satoshi/Fonts/Satoshi-Variable.ttf") format("ttf-variations");
  font-weight: 100 1000;
}

@font-face {
  font-family: "Satoshi";
  src: url("@fonts/Satoshi/Fonts/Satoshi-VariableItalic.ttf") format("ttf supports variations"),
       url("@fonts/Satoshi/Fonts/Satoshi-VariableItalic.ttf") format("ttf-variations");
  font-weight: 100 1000;
  font-style: italic;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.card {
  display: inline-block;
}